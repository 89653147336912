export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Barista",
    opciones: [
      "Barista", 
      "Mesero", 
      "Camarero", 
      "Chef", 
      "Cocinero", 
      "Repostero",
    ],
    respuestasCorrectas: ["Barista"],
    fonetica: ["Barista"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Receptionist",
    opciones: [
      "Recepcionista", 
      "Secretario", 
      "Asistente administrativo", 
      "Conserje", 
      "Telefonista", 
      "Auxiliar de oficina",
    ],
    respuestasCorrectas: ["Recepcionista"],
    fonetica: ["rɪˈsɛpʃənɪst"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Chef",
    opciones: [
      "Chef", 
      "Fotógrafo", 
      "Músico", 
      "Actor", 
      "Productor de cine", 
      "Arquitecto",
    ],
    respuestasCorrectas: ["Chef"],
    fonetica: ["ʃɛf"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Pastry cook",
    opciones: [
      "Pastelero o repostero", 
      "Cocinero", 
      "Panadero", 
      "Chef de pastelería", 
      "Confitero", 
      "Repostero",
    ],
    respuestasCorrectas: ["Pastelero o repostero"],
    fonetica: ["ˈpeɪstri kʊk"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Waiter",
    opciones: [
      "Camarero", 
      "Mesero", 
      "Atendente", 
      "Servidor", 
      "Garzón", 
      "Auxiliar de servicio",
    ],
    respuestasCorrectas: ["Camarero"],
    fonetica: ["ˈweɪtər"],
  },
  {
    id: 6,
    texto: '',
    palabra: "waitress",
    opciones: [
      "Camarera", 
      "Mesera", 
      "Servidor", 
      "Asistente de servicio", 
      "Garzona", 
      "Barista",
    ],
    respuestasCorrectas: ["Camarera"],
    fonetica: ["ˈweɪtrəs"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Housewife",
    opciones: [
      "Ama de casa", 
      "Dueña de casa", 
      "Hogarera", 
      "Encargada del hogar", 
      "Esposa", 
      "Cuidadora del hogar",
    ],
    respuestasCorrectas: ["Ama de casa"],
    fonetica: ["ˈhaʊˌswaɪf"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Secretary",
    opciones: [
      "Secretario", 
      "Asistente", 
      "Oficinista", 
      "Recepcionista", 
      "Administrador", 
      "Auxiliar administrativo",
    ],
    respuestasCorrectas: ["Secretario"],
    fonetica: ["ˈsɛkrəˌtɛri"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Hairdresser",
    opciones: [
      "Peluquero", 
      "Estilista", 
      "Barbero", 
      "Colorista", 
      "Cortador de cabello", 
      "Especialista en belleza",
    ],
    respuestasCorrectas: ["Peluquero"],
    fonetica: ["ˈhɛrˌdrɛsər"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Cleaner",
    opciones: [
      "Limpiador", 
      "Aseador", 
      "Encargado de limpieza", 
      "Higienista", 
      "Mantenimiento", 
      "Operario de limpieza",
    ],
    respuestasCorrectas: ["Limpiador"],
    fonetica: ["ˈklinər"],
  },
];