export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Politician",
    opciones: [
      "político", 
      "diplomático", 
      "legislador", 
      "senador", 
      "diputado", 
      "gobernante",
    ],
    respuestasCorrectas: ["político"],
    fonetica: ["ˌpɑləˈtɪʃən"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Nanny",
    opciones: [
      "Niñera", 
      "Cuidadora", 
      "Auxiliar de guardería", 
      "Ayudante de jardín infantil", 
      "Canguro", 
      "Guardián infantil",
    ],
    respuestasCorrectas: ["Niñera"],
    fonetica: ["ˈnæni"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Cashier",
    opciones: [
      "Cajero", 
      "Vendedor", 
      "Dependiente", 
      "Cobrador", 
      "Asistente de ventas", 
      "Reponedor",
    ],
    respuestasCorrectas: ["Cajero"],
    fonetica: ["kæˈʃɪr"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Cook",
    opciones: [
      "Cocinero", 
      "Chef", 
      "Panadero", 
      "Pastelero", 
      "Repostero", 
      "Mesero",
    ],
    respuestasCorrectas: ["Cocinero"],
    fonetica: ["kʊk"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Dressmaker",
    opciones: [
      "Modista", 
      "Sastre", 
      "Diseñador", 
      "Costurero", 
      "Confeccionista", 
      "Coser",
    ],
    respuestasCorrectas: ["Modista"],
    fonetica: ["ˈdrɛˌsmeɪkər"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Florist",
    opciones: [
      "Florista", 
      "Jardinero", 
      "Viverista", 
      "Cultivador", 
      "Paisajista", 
      "Botánico",
    ],
    respuestasCorrectas: ["Florista"],
    fonetica: ["ˈflɑrɪst"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Philosopher",
    opciones: [
      "Filósofo", 
      "Pensador", 
      "Académico", 
      "Teólogo", 
      "Escritor", 
      "Historiador",
    ],
    respuestasCorrectas: ["Filósofo"],
    fonetica: ["fəˈlɑsəfər"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Priest",
    opciones: [
      "Cura", 
      "Sacerdote", 
      "Pastor", 
      "Padre", 
      "Clérigo", 
      "Ministro",
    ],
    respuestasCorrectas: ["Cura"],
    fonetica: ["prist"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Librarian",
    opciones: [
      "Bibliotecario", 
      "Archivista", 
      "Documentalista", 
      "Asistente de biblioteca", 
      "Conservador", 
      "Investigador",
    ],
    respuestasCorrectas: ["Bibliotecario"],
    fonetica: ["laɪˈbrɛriən"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Baker",
    opciones: [
      "Panadero", 
      "Carnicero", 
      "Chef", 
      "Cocinero", 
      "Pastelero", 
      "Repostero",
    ],
    respuestasCorrectas: ["Panadero"],
    fonetica: ["ˈbeɪkər"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Miner",
    opciones: [
      "Minero", 
      "Excavador", 
      "Trabajador de mina", 
      "Picador", 
      "Extraccionista", 
      "Operador minero",
    ],
    respuestasCorrectas: ["Minero"],
    fonetica: ["ˈmaɪnər"],
  },
];