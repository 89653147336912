export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Doctor",
    opciones: [
      "Médico", 
      "Enfermero", 
      "Psicólogo", 
      "Fisioterapeuta", 
      "Odontólogo", 
      "Cirujano",
    ],
    respuestasCorrectas: ["Médico"],
    fonetica: ["ˈdɑktər"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Nurse",
    opciones: [
      "Enfermero", 
      "Médico", 
      "Psicólogo", 
      "Fisioterapeuta", 
      "Odontólogo", 
      "Cirujano",
    ],
    respuestasCorrectas: ["Enfermero"],
    fonetica: ["nɜrs"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Psychologist",
    opciones: [
      "Psicólogo", 
      "Médico", 
      "Enfermero", 
      "Fisioterapeuta", 
      "Odontólogo", 
      "Cirujano",
    ],
    respuestasCorrectas: ["Psicólogo"],
    fonetica: ["saɪˈkɑləʤəst"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Physical Therapist",
    opciones: [
      "Fisioterapeuta", 
      "Psicólogo", 
      "Médico", 
      "Enfermero", 
      "Odontólogo", 
      "Cirujano",
    ],
    respuestasCorrectas: ["Fisioterapeuta"],
    fonetica: ["ˈfɪzɪkəl ˈθɛrəpəst"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Dentist",
    opciones: [
      "Odontólogo", 
      "Médico", 
      "Enfermero", 
      "Psicólogo", 
      "Fisioterapeuta", 
      "Cirujano",
    ],
    respuestasCorrectas: ["Odontólogo"],
    fonetica: ["ˈdɛntəst"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Nutritionist",
    opciones: [
      "Nutricionista", 
      "Dietista", 
      "Médico", 
      "Enfermero", 
      "Psicólogo", 
      "Fisioterapeuta",
    ],
    respuestasCorrectas: ["Nutricionista"],
    fonetica: ["nuˈtrɪʃənɪst"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Radiologist",
    opciones: [
      "Radiólogo", 
      "Farmacéutico", 
      "Enfermero", 
      "Médico", 
      "Fisioterapeuta", 
      "Psicólogo",
    ],
    respuestasCorrectas: ["Radiólogo"],
    fonetica: ["ˌreɪdiˈɑləʤɪst"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Pharmacist",
    opciones: [
      "Farmacéutico", 
      "Radiólogo", 
      "Médico", 
      "Psicólogo", 
      "Fisioterapeuta", 
      "Enfermero",
    ],
    respuestasCorrectas: ["Farmacéutico"],
    fonetica: ["ˈfɑrməsɪst"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Veterinarian",
    opciones: [
      "Veterinario", 
      "Médico", 
      "Enfermero", 
      "Farmacéutico", 
      "Radiólogo", 
      "Psicólogo",
    ],
    respuestasCorrectas: ["Veterinario"],
    fonetica: ["ˌvɛtrəˈnɛriən"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Surgeon",
    opciones: [
      "Cirujano", 
      "Médico", 
      "Especialista", 
      "Doctor", 
      "Ortopedista", 
      "Neurocirujano",
    ],
    respuestasCorrectas: ["Cirujano"],
    fonetica: ["ˈsɜrʤən"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Optician",
    opciones: [
      "Óptico", 
      "Optometrista", 
      "Especialista en lentes", 
      "Oftalmólogo", 
      "Tecnólogo óptico", 
      "Consultor de visión",
    ],
    respuestasCorrectas: ["Óptico"],
    fonetica: ["ɑpˈtɪʃən"],
  },
];