export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Carpenter",
    opciones: [
      "Carpintero", 
      "Albañil", 
      "Ebanista", 
      "Constructor", 
      "Electricista", 
      "Mecánico",
    ],
    respuestasCorrectas: ["Carpintero"],
    fonetica: ["ˈkɑrpəntər"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Electrician",
    opciones: [
      "Electricista", 
      "Fontanero", 
      "Carpintero", 
      "Técnico en electrónica", 
      "Ingeniero eléctrico", 
      "Constructor",
    ],
    respuestasCorrectas: ["Electricista"],
    fonetica: ["ɪlɛkˈtrɪʃən"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Mechanic",
    opciones: [
      "Mecánico", 
      "Electricista", 
      "Fontanero", 
      "Carpintero", 
      "Soldador", 
      "Constructor",
    ],
    respuestasCorrectas: ["Mecánico"],
    fonetica: ["məˈkænɪk"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Construction worker",
    opciones: [
      "trabajador de construccion", 
      "Carpintero", 
      "Soldador", 
      "Plomero", 
      "Electricista", 
      "Mecánico",
    ],
    respuestasCorrectas: ["Trabajador de construccion"],
    fonetica: ["kənˈstrʌkʃən ˈwɜrkər"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Welder",
    opciones: [
      "Soldador", 
      "Carpintero", 
      "Albañil", 
      "Plomero", 
      "Electricista", 
      "Mecánico",
    ],
    respuestasCorrectas: ["Soldador"],
    fonetica: ["ˈwɛldər"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Plumber",
    opciones: [
      "Plomero", 
      "Fontanero", 
      "Electricista", 
      "Carpintero", 
      "Albañil", 
      "Mecánico",
    ],
    respuestasCorrectas: ["Plomero"],
    fonetica: ["ˈplʌmər"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Painter",
    opciones: [
      "Pintor", 
      "Artista", 
      "Decorador", 
      "Ilustrador", 
      "Colorista", 
      "Diseñador",
    ],
    respuestasCorrectas: ["Pintor"],
    fonetica: ["ˈpeɪntər"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Goldsmith",
    opciones: [
      "Orfebre", 
      "Joyero", 
      "Metalúrgico", 
      "Platero", 
      "Fundidor", 
      "Cincelador",
    ],
    respuestasCorrectas: ["Orfebre"],
    fonetica: ["ˈɡoʊldˌsmɪθ"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Tailor",
    opciones: [
      "Sastre", 
      "Modista", 
      "Confeccionista", 
      "Costurero", 
      "Arreglador de ropa", 
      "Diseñador de modas",
    ],
    respuestasCorrectas: ["Sastre"],
    fonetica: ["ˈteɪlər"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Cobbler",
    opciones: [
      "Zapatero", 
      "Reparador de calzado", 
      "Arreglador de zapatos", 
      "Zapatista", 
      "Zapatero remendón", 
      "Calzadero",
    ],
    respuestasCorrectas: ["Zapatero"],
    fonetica: ["ˈkɑblər"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Architect",
    opciones: [
      "Arquitecto", 
      "Diseñador gráfico", 
      "Técnico en soporte técnico", 
      "Escritor", 
      "Ilustrador", 
      "Ingeniero",
    ],
    respuestasCorrectas: ["Arquitecto"],
    fonetica: ["ˈɑrkəˌtɛkt"],
  },
];