export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Leg",
    opciones: [
      "Pierna", 
      "Pie", 
      "Brazo", 
      "Mano", 
      "Rodilla", 
      "Codo",
    ],
    respuestasCorrectas: ["Pierna"],
    fonetica: ["lɛɡ"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Foot",
    opciones: [
      "Pie", 
      "Mano", 
      "Cabeza", 
      "Brazo", 
      "Ojo", 
      "Cara",
    ],
    respuestasCorrectas: ["Pie"],
    fonetica: ["fʊt"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Toe",
    opciones: [
      "Dedo del pie", 
      "Dedo de la mano", 
      "Pie", 
      "Mano", 
      "Ojo", 
      "Nariz",
    ],
    respuestasCorrectas: ["Dedo del pie"],
    fonetica: ["toʊ"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Knee",
    opciones: [
      "Rodilla", 
      "Codo", 
      "Pierna", 
      "Brazo", 
      "Pie", 
      "Cabeza",
    ],
    respuestasCorrectas: ["Rodilla"],
    fonetica: ["ni"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Heel",
    opciones: [
      "Talón", 
      "Pie", 
      "Tobillo", 
      "Planta", 
      "Dedo del pie", 
      "Pantorrilla",
    ],
    respuestasCorrectas: ["Talón"],
    fonetica: ["hil"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Calf",
    opciones: [
      "Pantorrilla", 
      "Muslos", 
      "Espinilla", 
      "Caderas", 
      "Pierna", 
      "Rodilla",
    ],
    respuestasCorrectas: ["Pantorrilla"],
    fonetica: ["kæf"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Thighs",
    opciones: [
      "Muslos", 
      "Pantorrilla", 
      "Espinilla", 
      "Caderas", 
      "Pierna", 
      "Rodilla",
    ],
    respuestasCorrectas: ["Muslos"],
    fonetica: ["θaɪz"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Shin",
    opciones: [
      "Espinilla", 
      "Pantorrilla", 
      "Muslos", 
      "Caderas", 
      "Pierna", 
      "Rodilla",
    ],
    respuestasCorrectas: ["Espinilla"],
    fonetica: ["ʃɪn"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Hips",
    opciones: [
      "Caderas", 
      "Pantorrilla", 
      "Muslos", 
      "Espinilla", 
      "Pierna", 
      "Rodilla",
    ],
    respuestasCorrectas: ["Caderas"],
    fonetica: ["hɪps"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Ankle",
    opciones: [
      "Tobillo", 
      "Pie", 
      "Talón", 
      "Pantorrilla", 
      "Rodilla", 
      "Pierna",
    ],
    respuestasCorrectas: ["Tobillo"],
    fonetica: ["ˈæŋkəl"],
  },
];