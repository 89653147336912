export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Teacher",
    opciones: [
      "Maestro", 
      "Profesor", 
      "Docente", 
      "Instructor", 
      "Tutor", 
      "Educador",
    ],
    respuestasCorrectas: ["Maestro"],
    fonetica: ["ˈtiʧər"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Pedagogue",
    opciones: [
      "Pedagogo", 
      "Docente", 
      "Educador", 
      "Maestro", 
      "Tutor", 
      "Instructor",
    ],
    respuestasCorrectas: ["Pedagogo"],
    fonetica: ["Pedagogue"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Early childhood educator",
    opciones: [
      "Educador infantil", 
      "Docente de primera infancia", 
      "Maestro preescolar", 
      "Instructor de párvulos", 
      "Pedagogo infantil", 
      "Profesor de jardín de niños",
    ],
    respuestasCorrectas: ["Educador infantil"],
    fonetica: ["ˈɜrli ˈʧaɪldˌhʊd ˈɛʤəˌkeɪtər"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Academic Advisor",
    opciones: [
      "Orientador académico", 
      "Consejero educativo", 
      "Asesor académico", 
      "Tutor académico", 
      "Guía educativo", 
      "Consultor educativo",
    ],
    respuestasCorrectas: ["Orientador académico"],
    fonetica: ["ˌækəˈdɛmɪk ædˈvaɪzər"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Lecturer",
    opciones: [
      "Profesor universitario", 
      "Docente", 
      "Catedrático", 
      "Instructor", 
      "Educador", 
      "Académico",
    ],
    respuestasCorrectas: ["Profesor universitario"],
    fonetica: ["ˈlɛkʧərər"],
  },
];