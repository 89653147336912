export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Writer",
    opciones: [
      "Escritor", 
      "Diseñador gráfico", 
      "Arquitecto", 
      "Técnico en soporte técnico", 
      "Ilustrador", 
      "Ingeniero",
    ],
    respuestasCorrectas: ["Escritor"],
    fonetica: ["ˈraɪtər"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Illustrator",
    opciones: [
      "Ilustrador", 
      "Diseñador gráfico", 
      "Arquitecto", 
      "Escritor", 
      "Técnico en soporte técnico", 
      "Ingeniero",
    ],
    respuestasCorrectas: ["Ilustrador"],
    fonetica: ["ˈɪləˌstreɪtər"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Film Producer",
    opciones: [
      "Productor de cine", 
      "Director", 
      "Actor", 
      "Guionista", 
      "Diseñador de producción", 
      "Editor",
    ],
    respuestasCorrectas: ["Productor de cine"],
    fonetica: ["fɪlm prəˈdusər"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Actor",
    opciones: [
      "Actor", 
      "Productor de cine", 
      "Músico", 
      "Fotógrafo", 
      "Chef", 
      "Arquitecto",
    ],
    respuestasCorrectas: ["Actor"],
    fonetica: ["ˈæktər"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Musician",
    opciones: [
      "Músico", 
      "Actor", 
      "Productor de cine", 
      "Fotógrafo", 
      "Chef", 
      "Escritor",
    ],
    respuestasCorrectas: ["Músico"],
    fonetica: ["mjuˈzɪʃən"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Photographer",
    opciones: [
      "Fotógrafo", 
      "Músico", 
      "Actor", 
      "Productor de cine", 
      "Chef", 
      "Diseñador gráfico",
    ],
    respuestasCorrectas: ["Fotógrafo"],
    fonetica: ["fəˈtɑɡrəfər"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Graphic Designer",
    opciones: [
      "Diseñador gráfico", 
      "Técnico en soporte técnico", 
      "Arquitecto", 
      "Escritor", 
      "Ilustrador", 
      "Ingeniero",
    ],
    respuestasCorrectas: ["Diseñador gráfico"],
    fonetica: ["ˈɡræfɪk dɪˈzaɪnər"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Dancer",
    opciones: [
      "Bailarín", 
      "Coreógrafo", 
      "Instructor de danza", 
      "Artista de baile", 
      "Ejecutante", 
      "Bailador",
    ],
    respuestasCorrectas: ["Bailarín"],
    fonetica: ["ˈdænsər"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Journalist",
    opciones: [
      "Periodista", 
      "Reportero", 
      "Editor", 
      "Escritor", 
      "Comentarista", 
      "Corresponsal",
    ],
    respuestasCorrectas: ["Periodista"],
    fonetica: ["ˈʤɜrnələst"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Model",
    opciones: [
      "Modelo", 
      "Actor", 
      "Cantante", 
      "Bailarín", 
      "Fotógrafo", 
      "Diseñador",
    ],
    respuestasCorrectas: ["Modelo"],
    fonetica: ["ˈmɑdəl"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Actress",
    opciones: [
      "actriz", 
      "actora", 
      "interprete", 
      "dramaturga", 
      "performer", 
      "comediante",
    ],
    respuestasCorrectas: ["actriz"],
    fonetica: ["ˈæktrəs"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Author",
    opciones: [
      "Autor", 
      "Escritor", 
      "Novelista", 
      "Poeta", 
      "Dramaturgo", 
      "Redactor",
    ],
    respuestasCorrectas: ["Autor"],
    fonetica: ["ˈɔθər"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Singer",
    opciones: [
      "Cantante", 
      "Vocalista", 
      "Artista", 
      "Músico", 
      "Interprete", 
      "Solista",
    ],
    respuestasCorrectas: ["Cantante"],
    fonetica: ["ˈsɪŋər"],
  },
];