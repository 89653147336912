import React from "react";
import { EstructuraCuestionarioP2 } from "data/setUpQuizzesP2/questionnaireStructureP2.js";
import { preguntasIniciales } from "../wordsAnimals/l5.js";

const tituloLeccion = "Lección 5 P2: Animals";
const linkSiguiente = "/vocabulary/animals/leccion-5-p3";



function Leccion5AnimalsP2() {
  return (
    <EstructuraCuestionarioP2
      tituloLeccion={tituloLeccion}
      linkSiguiente={linkSiguiente}
      preguntasIniciales={preguntasIniciales}
    />
  );
}

export default Leccion5AnimalsP2;
