import React from "react";
import { Typography, Container, Card, CardContent, CardActions, Grid, Box, Button } from "@mui/material";
import { FaBook, FaList } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./home.css"; // Import the CSS file

const Home = () => {
  const cards = [
    {
      title: "VERBS",
      description: "• Domina los verbos esenciales a través de ejercicios interactivos.\n• Aprenda conjugaciones, tiempos verbales y usos en contextos del mundo real.",
      icon: <FaBook />,
      route: "/verbs"
    },
    {
      title: "VOCABULARY",
      description: "• Amplíe su vocabulario con listas de palabras temáticas.\n• Practica la pronunciación y el aprendizaje basado en el contexto.",
      icon: <FaList />,
      route: "/vocabulary"
    }
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container maxWidth="lg" sx={{ py: 2 }}>
        <Grid container spacing={3} direction="column">
          {cards.map((card, index) => (
            <Grid item xs={12} key={index}>
              <Card className="styled-card">
                <Box className="icon-wrapper">{card.icon}</Box>
                <CardContent className="card-content">
                  <Typography
                    variant="h5"
                    component="h2"
                    className="card-title"
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    className="card-description"
                  >
                    {card.description}
                  </Typography>
                </CardContent>
                <CardActions className="card-actions">
                  <Link to={card.route} style={{ textDecoration: 'none' }}>
                    <Button
                      variant="contained"
                      aria-label={`Learn more about ${card.title}`}
                      onClick={() => window.scrollTo(0, 0)}
                      className="styled-button"
                    >
                      Aprender
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Home;
