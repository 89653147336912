export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Hair",
    opciones: [
      "Cabello", 
      "Cabeza", 
      "Ojo", 
      "Nariz", 
      "Boca", 
      "Oreja",
    ],
    respuestasCorrectas: ["Cabello"],
    fonetica: ["hɛr"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Head",
    opciones: [
      "Cabeza", 
      "Mano", 
      "Pie", 
      "Brazo", 
      "Pierna", 
      "Espalda",
    ],
    respuestasCorrectas: ["Cabeza"],
    fonetica: ["hɛd"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Face",
    opciones: [
      "Cara", 
      "Ojo", 
      "Nariz", 
      "Boca", 
      "Oreja", 
      "Cuello",
    ],
    respuestasCorrectas: ["Cara"],
    fonetica: ["feɪs"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Eye",
    opciones: [
      "Ojo", 
      "Boca", 
      "Oreja", 
      "Nariz", 
      "Cara", 
      "Cuello",
    ],
    respuestasCorrectas: ["Ojo"],
    fonetica: ["aɪ"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Nose",
    opciones: [
      "Nariz", 
      "Ojo", 
      "Boca", 
      "Oreja", 
      "Cara", 
      "Cuello",
    ],
    respuestasCorrectas: ["Nariz"],
    fonetica: ["noʊz"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Mouth",
    opciones: [
      "Boca", 
      "Ojo", 
      "Nariz", 
      "Oreja", 
      "Cara", 
      "Cuello",
    ],
    respuestasCorrectas: ["Boca"],
    fonetica: ["maʊθ"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Eyebrow",
    opciones: [
      "Ceja", 
      "Pestaña", 
      "Ojo", 
      "Oreja", 
      "Nariz", 
      "Boca",
    ],
    respuestasCorrectas: ["Ceja"],
    fonetica: ["ˈaɪˌbraʊ"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Eyelash",
    opciones: [
      "Pestaña", 
      "Ojo", 
      "Ceja", 
      "Párpado", 
      "Labio", 
      "Nariz",
    ],
    respuestasCorrectas: ["Pestaña"],
    fonetica: ["ˈaɪˌlæʃ"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Eyelid",
    opciones: [
      "Párpado", 
      "Ojo", 
      "Ceja", 
      "Pestaña", 
      "Nariz", 
      "Barbilla",
    ],
    respuestasCorrectas: ["Párpado"],
    fonetica: ["ˈaɪˌlɪd"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Chin",
    opciones: [
      "Barbilla", 
      "Mandíbula", 
      "Labio", 
      "Nariz", 
      "Ojo", 
      "Ceja",
    ],
    respuestasCorrectas: ["Barbilla"],
    fonetica: ["ʧɪn"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Chin",
    opciones: [
      "Mentón", 
      "Nariz", 
      "Boca", 
      "Ojo", 
      "Oreja", 
      "Ceja",
    ],
    respuestasCorrectas: ["Mentón"],
    fonetica: ["ʧɪn"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Lip",
    opciones: [
      "Labio", 
      "Boca", 
      "Barbilla", 
      "Nariz", 
      "Párpado", 
      "Ceja",
    ],
    respuestasCorrectas: ["Labio"],
    fonetica: ["lɪp"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Teeth",
    opciones: [
      "Dientes", 
      "Muela", 
      "Lengua", 
      "Mandíbula", 
      "Labio", 
      "Paladar",
    ],
    respuestasCorrectas: ["Dientes"],
    fonetica: ["tiθ"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Tongue",
    opciones: [
      "Lengua", 
      "Labio", 
      "Diente", 
      "Mandíbula", 
      "Garganta", 
      "Paladar",
    ],
    respuestasCorrectas: ["Lengua"],
    fonetica: ["tʌŋ"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Eyelid",
    opciones: [
      "Párpado", 
      "Ojo", 
      "Ceja", 
      "Pestaña", 
      "Nariz", 
      "Boca",
    ],
    respuestasCorrectas: ["Párpado"],
    fonetica: ["ˈaɪˌlɪd"],
  },
];