import { Link } from "react-router-dom";
import "verbs/regular/regularHome.css";

const lecciones = [
  { id: 1, titulo: "Cabeza y Cara", preguntas: 15 },
  { id: 2, titulo: "Tronco", preguntas: 4 },
  { id: 3, titulo: "Extremidades Superiores", preguntas: 9 },
  { id: 4, titulo: "Extremidades Inferiores", preguntas: 10 },
  { id: 5, titulo: "Órganos y Partes Internas", preguntas: 12 },
];

function HumanBody() {
  return (
    <div className="inicio">
      {/* Título agregado para identificar que estamos en el verbo regular */}
      <div className="contenedor-titulo">
        <p className="titulo-verbo-regular">Human Body</p>
      </div>
      {lecciones.map((leccion) => (
        <Link
          className="modulo lecciones"
          to={`/vocabulary/humanBody/leccion-${leccion.id}`}
          key={leccion.id}
        >
          <p className="titulo">{leccion.titulo}</p>
          <p className="contenido">
            Compuesto por {leccion.preguntas} preguntas
          </p>
        </Link>
      ))}
    </div>
  );
}

export default HumanBody;
