import { Link } from "react-router-dom";
import "verbs/regular/regularHome.css";

const lecciones = [
  { id: 1, titulo: "Ciencias y Tecnología Avanzada", preguntas: 9 },
  { id: 2, titulo: "Equipos y Hardware (P1)", preguntas: 20 },
  { id: 3, titulo: "Equipos y Hardware (P2)", preguntas: 20 },
  { id: 4, titulo: "Equipos y Hardware (P3)", preguntas: 21 },
  { id: 5, titulo: "Personas y Actitudes", preguntas: 4 },
  { id: 6, titulo: "Acciones y Procesos (P1)", preguntas: 20 },
  { id: 7, titulo: "Acciones y Procesos (P2)", preguntas: 9 },
  { id: 8, titulo: "Otros Términos", preguntas: 18 },
];

function Technology() {
  return (
    <div className="inicio">
      {/* Título agregado para identificar que estamos en el verbo regular */}
      <div className="contenedor-titulo">
        <p className="titulo-verbo-regular">Technology</p>
      </div>
      {lecciones.map((leccion) => (
        <Link
          className="modulo lecciones"
          to={`/vocabulary/technology/leccion-${leccion.id}`}
          key={leccion.id}
        >
          <p className="titulo">{leccion.titulo}</p>
          <p className="contenido">
            Compuesto por {leccion.preguntas} preguntas
          </p>
        </Link>
      ))}
    </div>
  );
}

export default Technology;
