export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Fill",
    opciones: [
      "Llenar", 
      "Rellenar", 
      "Colmar", 
      "Saturar", 
      "Completar", 
      "Ocupar",
    ],
    respuestasCorrectas: ["Llenar"],
    fonetica: ["fɪl"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Finish",
    opciones: [
      "Terminar", 
      "Completar", 
      "Concluir", 
      "Finalizar", 
      "Rematar", 
      "Acabar",
    ],
    respuestasCorrectas: ["Terminar"],
    fonetica: ["ˈfɪnɪʃ"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Fix",
    opciones: [
      "Reparar", 
      "Arreglar", 
      "Solucionar", 
      "Remediar", 
      "Corregir", 
      "Restaurar",
    ],
    respuestasCorrectas: ["Reparar"],
    fonetica: ["fɪks"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Follow",
    opciones: [
      "Seguir", 
      "Perseguir", 
      "Acompañar", 
      "Rastrear", 
      "Ir detrás", 
      "Continuar",
    ],
    respuestasCorrectas: ["Seguir"],
    fonetica: ["ˈfɑloʊ"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Force",
    opciones: [
      "Forzar", 
      "Imponer", 
      "Obligar", 
      "Compeler", 
      "Coaccionar", 
      "Presionar",
    ],
    respuestasCorrectas: ["Forzar"],
    fonetica: ["fɔrs"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Fry",
    opciones: [
      "Freír", 
      "Sofreír", 
      "Dorarse", 
      "Cocinar", 
      "Sartén", 
      "Saltear",
    ],
    respuestasCorrectas: ["Freír"],
    fonetica: ["fraɪ"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Guess",
    opciones: [
      "Adivinar", 
      "Suponer", 
      "Estimar", 
      "Inferir", 
      "Deduzir", 
      "Intuir",
    ],
    respuestasCorrectas: ["Adivinar"],
    fonetica: ["ɡɛs"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Handle",
    opciones: [
      "Encargarse de", 
      "Gestionar", 
      "Manejar", 
      "Tratar", 
      "Atender", 
      "Dirigir",
    ],
    respuestasCorrectas: ["Encargarse de"],
    fonetica: ["ˈhændəl"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Hate",
    opciones: [
      "Odiar", 
      "Detestar", 
      "Aborrecer", 
      "Despreciar", 
      "Rechazar", 
      "Repudiar",
    ],
    respuestasCorrectas: ["Odiar"],
    fonetica: ["heɪt"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Help",
    opciones: [
      "Ayuda", 
      "Asistir", 
      "Socorrer", 
      "Auxiliar", 
      "Colaborar", 
      "Apoyar",
    ],
    respuestasCorrectas: ["Ayuda"],
    fonetica: ["hɛlp"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Hope",
    opciones: [
      "Esperar", 
      "Anhelar", 
      "Desear", 
      "Confiar", 
      "Soñar", 
      "Aspirar",
    ],
    respuestasCorrectas: ["Esperar"],
    fonetica: ["hoʊp"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Hug",
    opciones: [
      "Abrazar", 
      "Estrechar", 
      "Apretar", 
      "Sostener", 
      "Ceñir", 
      "Acurrucarse",
    ],
    respuestasCorrectas: ["Abrazar"],
    fonetica: ["hʌɡ"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Imagine",
    opciones: [
      "Imaginar", 
      "Visualizar", 
      "Concebir", 
      "Fantasía", 
      "Soñar", 
      "Inventar",
    ],
    respuestasCorrectas: ["Imaginar"],
    fonetica: ["ɪˈmæʤən"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Improve",
    opciones: [
      "Mejorar", 
      "Perfeccionar", 
      "Optimizar", 
      "Progresar", 
      "Aumentar", 
      "Refinar",
    ],
    respuestasCorrectas: ["Mejorar"],
    fonetica: ["ɪmˈpruv"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Increase",
    opciones: [
      "Aumentar", 
      "Incrementar", 
      "Expandir", 
      "Elevar", 
      "Ampliar", 
      "Intensificar",
    ],
    respuestasCorrectas: ["Aumentar"],
    fonetica: ["ˈɪnˌkris"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Iron",
    opciones: [
      "Planchar", 
      "Alisar", 
      "Desarrugar", 
      "Estirar", 
      "Enderezar", 
      "Pulir",
    ],
    respuestasCorrectas: ["Planchar"],
    fonetica: ["ˈaɪərn"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Join",
    opciones: [
      "Unir", 
      "Asociar", 
      "Integrar", 
      "Conectar", 
      "Adherir", 
      "Enlazar",
    ],
    respuestasCorrectas: ["Unir"],
    fonetica: ["ʤɔɪn"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Jump",
    opciones: [
      "Brincar", 
      "Saltar", 
      "Saltar", 
      "Dar un salto", 
      "Subir", 
      "Rebotar",
    ],
    respuestasCorrectas: ["Brincar"],
    fonetica: ["ʤʌmp"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Kill",
    opciones: [
      "Matar", 
      "Asesinar", 
      "Eliminar", 
      "Destruir", 
      "Exterminar", 
      "Aniquilar",
    ],
    respuestasCorrectas: ["Matar"],
    fonetica: ["kɪl"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Knock",
    opciones: [
      "Golpear", 
      "Tocar", 
      "Llamar", 
      "Aporrear", 
      "Sacudir", 
      "Golpeteo",
    ],
    respuestasCorrectas: ["Golpear"],
    fonetica: ["nɑk"],
  },
];