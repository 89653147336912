import React from "react";
import { EstructuraCuestionarioP2 } from "data/setUpQuizzesP2/questionnaireStructureP2.js";
import { preguntasIniciales } from "../wordsTechnology/l2.js";

const tituloLeccion = "Equipos y Hardware (P1) P2: Technology";
const linkSiguiente = "/vocabulary/technology/leccion-2-p3";



function Leccion2TechnologyP2() {
  return (
    <EstructuraCuestionarioP2
      tituloLeccion={tituloLeccion}
      linkSiguiente={linkSiguiente}
      preguntasIniciales={preguntasIniciales}
    />
  );
}

export default Leccion2TechnologyP2;
