export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Police Officer",
    opciones: [
      "Oficial de policía", 
      "Bombero", 
      "Guardia de seguridad", 
      "Detective", 
      "Investigador", 
      "Agente de tráfico",
    ],
    respuestasCorrectas: ["Oficial de policía"],
    fonetica: ["pəˈlis ˈɔfəsər"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Firefighter",
    opciones: [
      "Bombero", 
      "Oficial de policía", 
      "Guardia de seguridad", 
      "Paramédico", 
      "Investigador", 
      "Agente de tráfico",
    ],
    respuestasCorrectas: ["Bombero"],
    fonetica: ["ˈfaɪrˌfaɪtər"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Military",
    opciones: [
      "Militar", 
      "Soldado", 
      "Oficial", 
      "General", 
      "Teniente", 
      "Capitán",
    ],
    respuestasCorrectas: ["Militar"],
    fonetica: ["ˈmɪləˌtɛri"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Life guard",
    opciones: [
      "Socorrista", 
      "Salvavidas", 
      "Guardavidas", 
      "Monitor de natación", 
      "Instructor de buceo", 
      "Rescatista",
    ],
    respuestasCorrectas: ["Socorrista"],
    fonetica: ["laɪf ɡɑrd"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Caretaker",
    opciones: [
      "Portero o conserje", 
      "Niñero", 
      "Cuidador", 
      "Encargado", 
      "Guardia", 
      "Jardinero",
    ],
    respuestasCorrectas: ["Portero o conserje"],
    fonetica: ["ˈkɛrˌteɪkər"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Porter",
    opciones: [
      "Portero", 
      "Conserje", 
      "Recepcionista", 
      "Vigilante", 
      "Guarda", 
      "Guardia de seguridad",
    ],
    respuestasCorrectas: ["Portero"],
    fonetica: ["ˈpɔrtər"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Factory worker",
    opciones: [
      "Trabajador de fábrica", 
      "Obrero", 
      "Operario", 
      "Empleado industrial", 
      "Técnico de planta", 
      "Fabricante",
    ],
    respuestasCorrectas: ["Trabajador de fábrica"],
    fonetica: ["ˈfæktəri ˈwɜrkər"],
  },
];