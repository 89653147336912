export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Chest",
    opciones: [
      "Pecho", 
      "Espalda", 
      "Brazo", 
      "Pierna", 
      "Mano", 
      "Cuello",
    ],
    respuestasCorrectas: ["Pecho"],
    fonetica: ["ʧɛst"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Abdomen",
    opciones: [
      "Abdomen", 
      "Cabeza", 
      "Pierna", 
      "Brazo", 
      "Cuello", 
      "Espalda",
    ],
    respuestasCorrectas: ["Abdomen"],
    fonetica: ["æbˈdoʊmən"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Back",
    opciones: [
      "Espalda", 
      "Nariz", 
      "Cuello", 
      "Cabeza", 
      "Hombro", 
      "Brazo",
    ],
    respuestasCorrectas: ["Espalda"],
    fonetica: ["bæk"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Shoulder",
    opciones: [
      "Hombro", 
      "Brazo", 
      "Mano", 
      "Pierna", 
      "Cuello", 
      "Espalda",
    ],
    respuestasCorrectas: ["Hombro"],
    fonetica: ["ˈʃoʊldər"],
  },
];