import React from "react";
import { EstructuraCuestionarioP3 } from "data/setUpQuizzesP3/questionnaireStructureP3.js";
import { preguntasIniciales } from "../wordsTechnology/l8.js";
import "verbs/lessons.css"

const tituloLeccion = "Otros Términos P3: Technology";
const linkSiguiente = "/vocabulary/technology";



function Leccion8TechnologyP3() {
  return (
    <EstructuraCuestionarioP3
      tituloLeccion={tituloLeccion}
      linkSiguiente={linkSiguiente}
      preguntasIniciales={preguntasIniciales}
    />
  );
}

export default Leccion8TechnologyP3;
