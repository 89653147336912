export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Arm",
    opciones: [
      "Brazo", 
      "Pierna", 
      "Mano", 
      "Hombro", 
      "Cabeza", 
      "Espalda",
    ],
    respuestasCorrectas: ["Brazo"],
    fonetica: ["ɑrm"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Hand",
    opciones: [
      "Mano", 
      "Dedo de la mano", 
      "Brazo", 
      "Pierna", 
      "Cabeza", 
      "Ojo",
    ],
    respuestasCorrectas: ["Mano"],
    fonetica: ["hænd"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Finger",
    opciones: [
      "Dedo de la mano", 
      "Dedo del pie", 
      "Brazo", 
      "Pierna", 
      "Mano", 
      "Codo",
    ],
    respuestasCorrectas: ["Dedo de la mano"],
    fonetica: ["ˈfɪŋɡər"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Thumb",
    opciones: [
      "Pulgar", 
      "Dedo", 
      "Muñeca", 
      "Palma", 
      "Mano", 
      "Brazo",
    ],
    respuestasCorrectas: ["Pulgar"],
    fonetica: ["θʌm"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Wrist",
    opciones: [
      "Muñeca", 
      "Antebrazo", 
      "Codo", 
      "Mano", 
      "Dedo", 
      "Brazo",
    ],
    respuestasCorrectas: ["Muñeca"],
    fonetica: ["rɪst"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Elbow",
    opciones: [
      "Codo", 
      "Rodilla", 
      "Hombro", 
      "Mano", 
      "Pie", 
      "Brazo",
    ],
    respuestasCorrectas: ["Codo"],
    fonetica: ["ˈɛlˌboʊ"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Forearm",
    opciones: [
      "Antebrazo", 
      "Brazo", 
      "Codo", 
      "Muñeca", 
      "Mano", 
      "Hombro",
    ],
    respuestasCorrectas: ["Antebrazo"],
    fonetica: ["ˈfɔˌrɑrm"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Palm",
    opciones: [
      "Palma", 
      "Mano", 
      "Dedos", 
      "Muñeca", 
      "Brazo", 
      "Codo",
    ],
    respuestasCorrectas: ["Palma"],
    fonetica: ["pɑm"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Nails",
    opciones: [
      "Uñas", 
      "Dedos", 
      "Mano", 
      "Pie", 
      "Brazo", 
      "Muñeca",
    ],
    respuestasCorrectas: ["Uñas"],
    fonetica: ["neɪlz"],
  },
];