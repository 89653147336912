import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Container, Card, CardContent, CardActions, Grid, Box, Button, CircularProgress } from "@mui/material";
import "./verbs.css"; // Import the CSS file

function Verbs() {
  const cards = [
    {
      title: "VERB REGULAR",
      route: "/verbs/regular",
      img: "/img/irregular.jpg"
    },
    {
      title: "VERB IRREGULAR",
      route: "/verbs/irregular",
      img: "/img/regular.jpg"
    }
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container maxWidth="lg" sx={{ py: 2 }}>
        <Grid container spacing={3} direction="column">
          {cards.map((card, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card className="verbs-card">
                <CardContent className="verbs-card-content">
                  <ImageWithLoader src={card.img} alt={card.title} />
                  <Typography
                    variant="h5"
                    component="h2"
                    className="verbs-card-title"
                  >
                    {card.title}
                  </Typography>
                  <CardActions className="verbs-card-actions">
                    <Link to={card.route} style={{ textDecoration: 'none' }}>
                      <Button
                        variant="contained"
                        aria-label={`Learn more about ${card.title}`}
                        onClick={() => window.scrollTo(0, 0)}
                        className="verbs-button"
                      >
                        Learn More
                      </Button>
                    </Link>
                  </CardActions>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}

function ImageWithLoader({ src, alt }) {
  const [loaded, setLoaded] = useState(false);

  return (
    <Box className="image-loader-wrapper">
      {!loaded && <CircularProgress />}
      <img
        src={src}
        alt={alt}
        className={`verbs-card-image ${loaded ? "loaded" : ""}`}
        onLoad={() => setLoaded(true)}
        style={{ display: loaded ? "block" : "none" }}
      />
    </Box>
  );
}

export default Verbs;
