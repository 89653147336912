import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  TextareaAutosize,
  MenuItem,
  Select,
  Typography,
  Card,
  CardContent,
  IconButton,
  Grid,
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRight,
  Translate,
  PlayCircle,
  CheckCircle,
  PanTool,
} from "@mui/icons-material";

const LanguageLearningInterface = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [currentLesson, setCurrentLesson] = useState(1);
  const [inputText, setInputText] = useState("");
  const [correctWords, setCorrectWords] = useState([]);
  const [incorrectWords, setIncorrectWords] = useState([]);

  const languages = [
    { id: 1, name: "English", code: "en" },
    { id: 2, name: "Spanish", code: "es" },
  ];

  const handleCheck = () => {
    const words = inputText.split(" ");
    const newCorrectWords = [];
    const newIncorrectWords = [];

    words.forEach((word) => {
      if (Math.random() > 0.5) {
        newCorrectWords.push(word);
      } else {
        newIncorrectWords.push(word);
      }
    });

    setCorrectWords(newCorrectWords);
    setIncorrectWords(newIncorrectWords);
    setInputText("");
  };

  const handlePlayback = () => {
    console.log("Playing audio...");
  };

  const navigateLesson = (direction) => {
    if (direction === "next" && currentLesson < 10) {
      setCurrentLesson(currentLesson + 1);
    } else if (direction === "prev" && currentLesson > 1) {
      setCurrentLesson(currentLesson - 1);
    }
  };

  return (
    <Box sx={{ minHeight: "100vh", bgcolor: "background.default", p: 4 }}>
      <Box
        sx={{
          maxWidth: 800,
          mx: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        {/* Header */}
        <Box textAlign="center">
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            Practice
          </Typography>
          <Typography variant="h6" color="text.secondary">
            Lesson {currentLesson}
          </Typography>
          {/* Language Selector */}
          <Box mt={2}>
            <Select
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
              startAdornment={<Translate sx={{ mr: 1 }} />}
              variant="outlined"
              displayEmpty
              fullWidth
            >
              {languages.map((lang) => (
                <MenuItem key={lang.id} value={lang.name}>
                  {lang.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>

        {/* Navigation */}
        <Box display="flex" justifyContent="center" alignItems="center" gap={2}>
          <IconButton
            onClick={() => navigateLesson("prev")}
            disabled={currentLesson === 1}
          >
            <ChevronLeft />
          </IconButton>
          <Typography variant="h6">Lesson {currentLesson}</Typography>
          <IconButton
            onClick={() => navigateLesson("next")}
            disabled={currentLesson === 10}
          >
            <ChevronRight />
          </IconButton>
        </Box>

        {/* Word Results */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Card sx={{ bgcolor: "#DFFCE3" }}>
              <CardContent>
                <Typography variant="subtitle1" fontWeight="bold" color="#00B14F">
                  Correct: 
                </Typography>
                {correctWords.map((word, index) => (
                  <Typography key={index} color="success.main">
                    {word}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6}>
            <Card sx={{ bgcolor: "#FFDADA" }}>
              <CardContent>
                <Typography variant="subtitle1" fontWeight="bold" color="#FF3C38">
                  Incorrect: 
                </Typography>
                {incorrectWords.map((word, index) => (
                  <Typography key={index} color="error.main">
                    {word}
                  </Typography>
                ))}
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Main Content */}
        <Card>
          <CardContent>
            <Box display="flex" justifyContent="flex-end" mb={1}>
              <IconButton onClick={handlePlayback}>
              <PlayCircle fontSize="large" />
              </IconButton>
            </Box>
            <TextareaAutosize
              minRows={5}
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              placeholder="Type your answer here..."
              style={{
                width: "90%",
                padding: "1rem",
                border: "1px solid #ccc",
                borderRadius: "8px",
                outline: "none",
                resize: "none",
              }}
            />
            <Button
              onClick={handleCheck}
              variant="contained"
              color="primary"
              fullWidth
              startIcon={<CheckCircle />}
              sx={{ mt: 2 }}
            >
              Check
            </Button>
          </CardContent>
        </Card>

        {/* Hand Icon */}
        <Box display="flex" justifyContent="flex-end">
          <IconButton>
            <PanTool />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default LanguageLearningInterface;
