export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Software Developer",
    opciones: [
      "Desarrollador de software", 
      "Programador", 
      "Ingeniero de software", 
      "Analista de sistemas", 
      "Especialista en TI", 
      "Arquitecto de software",
    ],
    respuestasCorrectas: ["Desarrollador de software"],
    fonetica: ["ˈsɔfˌtwɛr dɪˈvɛləpər"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Systems Engineer",
    opciones: [
      "Ingeniero de sistemas", 
      "Administrador de redes", 
      "Especialista en ciberseguridad", 
      "Diseñador UX/UI", 
      "Científico de datos", 
      "Desarrollador de software",
    ],
    respuestasCorrectas: ["Ingeniero de sistemas"],
    fonetica: ["ˈsɪstəmz ˈɛnʤəˈnɪr"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Network Administrator",
    opciones: [
      "Administrador de redes", 
      "Ingeniero de sistemas", 
      "Especialista en ciberseguridad", 
      "Diseñador UX/UI", 
      "Científico de datos", 
      "Desarrollador de software",
    ],
    respuestasCorrectas: ["Administrador de redes"],
    fonetica: ["ˈnɛˌtwɜrk ədˈmɪnəˌstreɪtər"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Cybersecurity Specialist",
    opciones: [
      "Especialista en ciberseguridad", 
      "Ingeniero de sistemas", 
      "Administrador de redes", 
      "Diseñador UX/UI", 
      "Científico de datos", 
      "Desarrollador de software",
    ],
    respuestasCorrectas: ["Especialista en ciberseguridad"],
    fonetica: ["Cybersecurity ˈspɛʃələst"],
  },
  {
    id: 5,
    texto: '',
    palabra: "UX/UI Designer",
    opciones: [
      "Diseñador UX/UI", 
      "Ingeniero de sistemas", 
      "Administrador de redes", 
      "Especialista en ciberseguridad", 
      "Científico de datos", 
      "Desarrollador de software",
    ],
    respuestasCorrectas: ["Diseñador UX/UI"],
    fonetica: ["ju-ɛks/ju-aɪ dɪˈzaɪnər"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Data Scientist",
    opciones: [
      "Científico de datos", 
      "Ingeniero de sistemas", 
      "Administrador de redes", 
      "Especialista en ciberseguridad", 
      "Diseñador UX/UI", 
      "Desarrollador de software",
    ],
    respuestasCorrectas: ["Científico de datos"],
    fonetica: ["ˈdeɪtə ˈsaɪəntɪst"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Technical Support Technician",
    opciones: [
      "Técnico en soporte técnico", 
      "Diseñador gráfico", 
      "Arquitecto", 
      "Escritor", 
      "Ilustrador", 
      "Ingeniero",
    ],
    respuestasCorrectas: ["Técnico en soporte técnico"],
    fonetica: ["ˈtɛknɪkəl səˈpɔrt tɛkˈnɪʃən"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Environmental Engineer",
    opciones: [
      "Ingeniero ambiental", 
      "Ingeniero civil", 
      "Ingeniero industrial", 
      "Ingeniero químico", 
      "Ingeniero agrónomo", 
      "Ingeniero mecánico",
    ],
    respuestasCorrectas: ["Ingeniero ambiental"],
    fonetica: ["ɪnˌvaɪrənˈmɛntəl ˈɛnʤəˈnɪr"],
  },
];