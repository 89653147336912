export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Monk",
    opciones: [
      "Monje", 
      "Religioso", 
      "Fray", 
      "Hermitaño", 
      "Monacato", 
      "Abad",
    ],
    respuestasCorrectas: ["Monje"],
    fonetica: ["mʌŋk"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Nun",
    opciones: [
      "Monja", 
      "Religiosa", 
      "Hermana", 
      "Sor", 
      "Novicia", 
      "Clarisas",
    ],
    respuestasCorrectas: ["Monja"],
    fonetica: ["nʌn"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Farmer",
    opciones: [
      "Granjero", 
      "Agricultor", 
      "Campesino", 
      "Horticultor", 
      "Productor", 
      "Fincalero",
    ],
    respuestasCorrectas: ["Granjero"],
    fonetica: ["ˈfɑrmər"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Fisherman",
    opciones: [
      "Pescador", 
      "Marinero", 
      "Pescador artesanal", 
      "Barquero", 
      "Navegante", 
      "Aquacultor",
    ],
    respuestasCorrectas: ["Pescador"],
    fonetica: ["ˈfɪʃərˌmæn"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Gardener",
    opciones: [
      "Jardinero", 
      "Paisajista", 
      "Horticultor", 
      "Florista", 
      "Botánico", 
      "Cultivador",
    ],
    respuestasCorrectas: ["Jardinero"],
    fonetica: ["ˈɡɑrdənər"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Counselor",
    opciones: [
      "Consejero", 
      "Asesor", 
      "Orientador", 
      "Consultor", 
      "Guía", 
      "Mentor",
    ],
    respuestasCorrectas: ["Consejero"],
    fonetica: ["ˈkaʊnsələr"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Judge",
    opciones: [
      "Juez", 
      "Magistrado", 
      "Arbitro", 
      "Tribuno", 
      "Justiciero", 
      "Jurista",
    ],
    respuestasCorrectas: ["Juez"],
    fonetica: ["ʤʌʤ"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Sportsman",
    opciones: [
      "Deportista", 
      "Atleta", 
      "Jugador", 
      "Competidor", 
      "Corredor", 
      "Entrenador",
    ],
    respuestasCorrectas: ["Deportista"],
    fonetica: ["ˈspɔrtsmən"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Translator",
    opciones: [
      "Traductor", 
      "Intérprete", 
      "Lingüista", 
      "Especialista en idiomas", 
      "Traductor de textos", 
      "Adaptador",
    ],
    respuestasCorrectas: ["Traductor"],
    fonetica: ["trænˈsleɪtər"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Astronaut",
    opciones: [
      "Astronauta", 
      "Cosmonauta", 
      "Explorador espacial", 
      "Ingeniero aeroespacial", 
      "Piloto de naves", 
      "Tripulante espacial",
    ],
    respuestasCorrectas: ["Astronauta"],
    fonetica: ["ˈæstrəˌnɑt"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Butcher",
    opciones: [
      "Carnicero", 
      "Cortador de carne", 
      "Despostador", 
      "Proveedor de carne", 
      "Vendedor de carne", 
      "Especialista en carnes",
    ],
    respuestasCorrectas: ["Carnicero"],
    fonetica: ["ˈbʊʧər"],
  },
];