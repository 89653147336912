import React from "react";
import { EstructuraCuestionarioP3 } from "data/setUpQuizzesP3/questionnaireStructureP3.js";
import { preguntasIniciales } from "../wordsProfessions/l10";

const tituloLeccion = "Administración y Negocios P3: Professions";
const linkSiguiente = "/vocabulary/professions/leccion-11";



function Leccion10ProfessionsP3() {
  return (
    <EstructuraCuestionarioP3
      tituloLeccion={tituloLeccion}
      linkSiguiente={linkSiguiente}
      preguntasIniciales={preguntasIniciales}
    />
  );
}

export default Leccion10ProfessionsP3;