import React from "react";
import { EstructuraCuestionarioP3 } from "data/setUpQuizzesP3/questionnaireStructureP3.js";
import { preguntasIniciales } from "../wordsTechnology/l2.js";

const tituloLeccion = "Equipos y Hardware (P1) P3: Technology";
const linkSiguiente = "/vocabulary/technology/leccion-3";



function Leccion2TechnologyP3() {
  return (
    <EstructuraCuestionarioP3
      tituloLeccion={tituloLeccion}
      linkSiguiente={linkSiguiente}
      preguntasIniciales={preguntasIniciales}
    />
  );
}

export default Leccion2TechnologyP3;
