import React from "react";
import { EstructuraCuestionario } from "data/setUpQuizzes/questionnaireStructure.js";
import { preguntasIniciales } from "../wordsTechnology/l5.js";
import "verbs/lessons.css"

const tituloLeccion = "Personas y Actitudes: Technology";
const linkSiguiente = "/vocabulary/technology/leccion-5-p2";



function Leccion5Technology() {
  return (
    <EstructuraCuestionario
      tituloLeccion={tituloLeccion}
      linkSiguiente={linkSiguiente}
      preguntasIniciales={preguntasIniciales}
    />
  );
}

export default Leccion5Technology;
