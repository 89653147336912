export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Meteorologist",
    opciones: [
      "Meteorólogo", 
      "Astrónomo", 
      "Climatólogo", 
      "Geólogo", 
      "Oceanógrafo", 
      "Ambientalista",
    ],
    respuestasCorrectas: ["Meteorólogo"],
    fonetica: ["ˌmitiəˈrɑləʤɪst"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Chemist",
    opciones: [
      "Químico", 
      "Farmacéutico", 
      "Biólogo", 
      "Físico", 
      "Microbiólogo", 
      "Bioquímico",
    ],
    respuestasCorrectas: ["Químico"],
    fonetica: ["ˈkɛmɪst"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Astronomer",
    opciones: [
      "Astrónomo", 
      "Astrofísico", 
      "Meteorólogo", 
      "Geólogo", 
      "Cartógrafo", 
      "Físico",
    ],
    respuestasCorrectas: ["Astrónomo"],
    fonetica: ["əˈstrɑnəmər"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Biologist",
    opciones: [
      "Biólogo", 
      "Químico", 
      "Físico", 
      "Botánico", 
      "Zoologo", 
      "Microbiólogo",
    ],
    respuestasCorrectas: ["Biólogo"],
    fonetica: ["baɪˈɑləʤɪst"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Geologist",
    opciones: [
      "Geólogo", 
      "Geógrafo", 
      "Cartógrafo", 
      "Hidrólogo", 
      "Sismólogo", 
      "Vulcanólogo",
    ],
    respuestasCorrectas: ["Geólogo"],
    fonetica: ["ʤiˈɑləʤəst"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Physicist",
    opciones: [
      "Físico", 
      "Científico", 
      "Investigador", 
      "Especialista en física", 
      "Profesor de física", 
      "Estudioso de la materia",
    ],
    respuestasCorrectas: ["Físico"],
    fonetica: ["ˈfɪzɪsɪst"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Researcher",
    opciones: [
      "Investigador", 
      "Científico", 
      "Analista", 
      "Estudioso", 
      "Académico", 
      "Examinador",
    ],
    respuestasCorrectas: ["Investigador"],
    fonetica: ["ˈrisərʧər"],
  },
];