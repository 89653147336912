export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Accountant",
    opciones: [
      "Contador", 
      "Economista", 
      "Auditor", 
      "Analista financiero", 
      "Consultor empresarial", 
      "Abogado",
    ],
    respuestasCorrectas: ["Contador"],
    fonetica: ["əˈkaʊntənt"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Economist",
    opciones: [
      "Economista", 
      "Contador", 
      "Analista financiero", 
      "Consultor empresarial", 
      "Auditor", 
      "Abogado",
    ],
    respuestasCorrectas: ["Economista"],
    fonetica: ["ɪˈkɑnəmɪst"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Human Resources Specialist",
    opciones: [
      "Especialista en recursos humanos", 
      "Contador", 
      "Economista", 
      "Analista financiero", 
      "Consultor empresarial", 
      "Abogado",
    ],
    respuestasCorrectas: ["Especialista en recursos humanos"],
    fonetica: ["ˈhjumən ˈrisɔrsɪz ˈspɛʃələst"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Financial Analyst",
    opciones: [
      "Analista financiero", 
      "Economista", 
      "Contador", 
      "Consultor empresarial", 
      "Auditor", 
      "Abogado",
    ],
    respuestasCorrectas: ["Analista financiero"],
    fonetica: ["fəˈnænʃəl ˈænələst"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Business Consultant",
    opciones: [
      "Consultor empresarial", 
      "Contador", 
      "Economista", 
      "Analista financiero", 
      "Auditor", 
      "Abogado",
    ],
    respuestasCorrectas: ["Consultor empresarial"],
    fonetica: ["ˈbɪznəs kənˈsʌltənt"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Project Manager",
    opciones: [
      "Gerente de proyectos", 
      "Jefe de equipo", 
      "Director de operaciones", 
      "Supervisor", 
      "Gerente de producto", 
      "Coordinador",
    ],
    respuestasCorrectas: ["Gerente de proyectos"],
    fonetica: ["ˈprɑʤɛkt ˈmænəʤər"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Businessman",
    opciones: [
      "Empresario", 
      "Contador", 
      "Abogado", 
      "Consultor", 
      "Ejecutivo", 
      "Gerente",
    ],
    respuestasCorrectas: ["Empresario"],
    fonetica: ["ˈbɪznəˌsmæn"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Businesswoman",
    opciones: [
      "Empresaria", 
      "Secretaria", 
      "Contadora", 
      "Abogada", 
      "Consultora", 
      "Gerenta",
    ],
    respuestasCorrectas: ["Empresaria"],
    fonetica: ["ˈbɪznɪˌswʊmən"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Office worker",
    opciones: [
      "Oficinista", 
      "Empleado de oficina", 
      "Asistente administrativo", 
      "Secretario", 
      "Recepcionista", 
      "Auxiliar administrativo",
    ],
    respuestasCorrectas: ["Oficinista"],
    fonetica: ["ˈɔfəs ˈwɜrkər"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Travel agent",
    opciones: [
      "Agente de viajes", 
      "Consultor de viajes", 
      "Operador turístico", 
      "Asesor de viajes", 
      "Planificador de viajes", 
      "Coordinador de viajes",
    ],
    respuestasCorrectas: ["Agente de viajes"],
    fonetica: ["ˈtrævəl ˈeɪʤənt"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Real estate agent",
    opciones: [
      "Agente de bienes raíces", 
      "Agente inmobiliario", 
      "Corredor de propiedades", 
      "Consultor inmobiliario", 
      "Vendedor de bienes raíces", 
      "Intermediario de propiedades",
    ],
    respuestasCorrectas: ["Agente de bienes raíces"],
    fonetica: ["riəl ɪˈsteɪt ˈeɪʤənt"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Salesman",
    opciones: [
      "Vendedor", 
      "Comerciante", 
      "Agente de ventas", 
      "Distribuidor", 
      "Mercader", 
      "Consultor de ventas",
    ],
    respuestasCorrectas: ["Vendedor"],
    fonetica: ["ˈseɪlzmən"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Shop Assistant",
    opciones: [
      "Asistente de ventas", 
      "Dependiente", 
      "Empleado de tienda", 
      "Cajero", 
      "Auxiliar de tienda", 
      "Encargado de mostrador",
    ],
    respuestasCorrectas: ["Asistente de ventas"],
    fonetica: ["ʃɑp əˈsɪstənt"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Social worker",
    opciones: [
      "Trabajador social", 
      "Consejero", 
      "Asistente social", 
      "Terapeuta", 
      "Educador", 
      "Orientador",
    ],
    respuestasCorrectas: ["Trabajador social"],
    fonetica: ["ˈsoʊʃəl ˈwɜrkər"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Lawyer",
    opciones: [
      "Abogado", 
      "Fiscal", 
      "Juez", 
      "Notario", 
      "Procurador", 
      "Defensor",
    ],
    respuestasCorrectas: ["Abogado"],
    fonetica: ["ˈlɔjər"],
  },
];