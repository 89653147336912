export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Boss",
    opciones: [
      "Jefe", 
      "Encargado", 
      "Supervisor", 
      "Director", 
      "Gerente", 
      "Administrador",
    ],
    respuestasCorrectas: ["Jefe"],
    fonetica: ["bɑs"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Company",
    opciones: [
      "Empresa", 
      "Negocio", 
      "Compañía", 
      "Firma", 
      "Entidad", 
      "Organización",
    ],
    respuestasCorrectas: ["Empresa"],
    fonetica: ["ˈkʌmpəni"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Contract",
    opciones: [
      "Contrato", 
      "Pacto", 
      "Convenio", 
      "Trato", 
      "Compromiso", 
      "Alianza",
    ],
    respuestasCorrectas: ["Contrato"],
    fonetica: ["ˈkɑnˌtrækt"],
  },
  {
    id: 4,
    texto: '',
    palabra: "Resume",
    opciones: [
      "Currículum", 
      "Hoja de vida", 
      "Perfil profesional", 
      "Sumario", 
      "Historia laboral", 
      "Biografía profesional",
    ],
    respuestasCorrectas: ["Currículum"],
    fonetica: ["rɪˈzum"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Deadline",
    opciones: [
      "Fecha de entrega", 
      "Límite", 
      "Fecha límite", 
      "Tope", 
      "Vencimiento", 
      "Cierre",
    ],
    respuestasCorrectas: ["Fecha de entrega"],
    fonetica: ["ˈdɛˌdlaɪn"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Dismissal",
    opciones: [
      "Despido", 
      "Cese", 
      "Terminación", 
      "Destitución", 
      "Expulsión", 
      "Separación",
    ],
    respuestasCorrectas: ["Despido"],
    fonetica: ["dɪˈsmɪsəl"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Employee",
    opciones: [
      "Empleado", 
      "Trabajador", 
      "Colaborador", 
      "Asociado", 
      "Personal", 
      "Obrero",
    ],
    respuestasCorrectas: ["Empleado"],
    fonetica: ["ɛmˈplɔɪi"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Hiring",
    opciones: [
      "Contratación", 
      "Reclutamiento", 
      "Incorporación", 
      "Seleccionamiento", 
      "Enganche", 
      "Empleo",
    ],
    respuestasCorrectas: ["Contratación"],
    fonetica: ["ˈhaɪrɪŋ"],
  },
  {
    id: 9,
    texto: '',
    palabra: "Holiday",
    opciones: [
      "Vacaciones", 
      "Receso", 
      "Descanso", 
      "Día festivo", 
      "Asueto", 
      "Licencia",
    ],
    respuestasCorrectas: ["Vacaciones"],
    fonetica: ["ˈhɑləˌdeɪ"],
  },
  {
    id: 10,
    texto: '',
    palabra: "Home office",
    opciones: [
      "Oficina en casa", 
      "Teletrabajo", 
      "Trabajo remoto", 
      "Trabajo desde casa", 
      "Oficina en el hogar", 
      "Trabajo domiciliario",
    ],
    respuestasCorrectas: ["Oficina en casa"],
    fonetica: ["hoʊm ˈɔfəs"],
  },
  {
    id: 11,
    texto: '',
    palabra: "Office",
    opciones: [
      "Oficina", 
      "Despacho", 
      "Consultorio", 
      "Departamento", 
      "Buro", 
      "Sede",
    ],
    respuestasCorrectas: ["Oficina"],
    fonetica: ["ˈɔfəs"],
  },
  {
    id: 12,
    texto: '',
    palabra: "Organization chart",
    opciones: [
      "Organigrama", 
      "Esquema organizacional", 
      "Estructura organizativa", 
      "Gráfico organizacional", 
      "Mapa organizacional", 
      "Diagrama de la organización",
    ],
    respuestasCorrectas: ["Organigrama"],
    fonetica: ["ˌɔrɡənəˈzeɪʃən ʧɑrt"],
  },
  {
    id: 13,
    texto: '',
    palabra: "Travel allowances",
    opciones: [
      "Viáticos", 
      "Subsidios de viaje", 
      "Asignaciones de viaje", 
      "Dietas", 
      "Compensaciones de viaje", 
      "Gastos de viaje",
    ],
    respuestasCorrectas: ["Viáticos"],
    fonetica: ["ˈtrævəl əˈlaʊənsɪz"],
  },
  {
    id: 14,
    texto: '',
    palabra: "Promotion",
    opciones: [
      "Ascenso", 
      "Promoción", 
      "Mejora", 
      "Avance", 
      "Progreso", 
      "Elevación",
    ],
    respuestasCorrectas: ["Ascenso"],
    fonetica: ["prəˈmoʊʃən"],
  },
  {
    id: 15,
    texto: '',
    palabra: "Raise",
    opciones: [
      "Aumento", 
      "Incremento", 
      "Subida", 
      "Mejora salarial", 
      "Incremento salarial", 
      "Reajuste",
    ],
    respuestasCorrectas: ["Aumento"],
    fonetica: ["reɪz"],
  },
  {
    id: 16,
    texto: '',
    palabra: "Report",
    opciones: [
      "Reporte", 
      "Informe", 
      "Documento", 
      "Memorándum", 
      "Relato", 
      "Exposición",
    ],
    respuestasCorrectas: ["Reporte"],
    fonetica: ["rɪˈpɔrt"],
  },
  {
    id: 17,
    texto: '',
    palabra: "Salary",
    opciones: [
      "Sueldo", 
      "Salario", 
      "Remuneración", 
      "Retribución", 
      "Honorarios", 
      "Paga",
    ],
    respuestasCorrectas: ["Sueldo"],
    fonetica: ["ˈsæləri"],
  },
  {
    id: 18,
    texto: '',
    palabra: "Teamwork",
    opciones: [
      "Equipo de trabajo", 
      "Trabajo en equipo", 
      "Colaboración", 
      "Cooperación", 
      "Labor conjunta", 
      "Sinergia",
    ],
    respuestasCorrectas: ["Equipo de trabajo"],
    fonetica: ["ˈtimˌwɜrk"],
  },
  {
    id: 19,
    texto: '',
    palabra: "Videoconference",
    opciones: [
      "Videoconferencia", 
      "Reunión virtual", 
      "Teleconferencia", 
      "Conferencia por video", 
      "Llamada de video", 
      "Videochat",
    ],
    respuestasCorrectas: ["Videoconferencia"],
    fonetica: ["ˈvidioʊˌkɑnfrəns"],
  },
  {
    id: 20,
    texto: '',
    palabra: "Work free day",
    opciones: [
      "Días libres", 
      "Día de descanso", 
      "Jornada libre", 
      "Día sin trabajo", 
      "Día de vacaciones", 
      "Asueto",
    ],
    respuestasCorrectas: ["Días libres"],
    fonetica: ["wɜrk fri deɪ"],
  },
  {
    id: 21,
    texto: '',
    palabra: "Work Schedule",
    opciones: [
      "Horario", 
      "Agenda laboral", 
      "Calendario de trabajo", 
      "Itinerario laboral", 
      "Plan de trabajo", 
      "Programa de trabajo",
    ],
    respuestasCorrectas: ["Horario"],
    fonetica: ["wɜrk ˈskɛʤʊl"],
  },
];