export const preguntasIniciales = [
  {
    id: 1,
    texto: '',
    palabra: "Pilot",
    opciones: [
      "Piloto", 
      "Azafato", 
      "Ingeniero de vuelo", 
      "Controlador aéreo", 
      "Copiloto", 
      "Mecánico de aviación",
    ],
    respuestasCorrectas: ["Piloto"],
    fonetica: ["ˈpaɪlət"],
  },
  {
    id: 2,
    texto: '',
    palabra: "Flight Attendant",
    opciones: [
      "Auxiliar de vuelo", 
      "Piloto", 
      "Ingeniero de vuelo", 
      "Controlador aéreo", 
      "Mecánico de aviación", 
      "Oficial de abordo",
    ],
    respuestasCorrectas: ["Auxiliar de vuelo"],
    fonetica: ["flaɪt əˈtɛndənt"],
  },
  {
    id: 3,
    texto: '',
    palabra: "Lorry driver",
    opciones: [
      "Camionero", 
      "Conductor de camión", 
      "Transportista", 
      "Chofer", 
      "Repartidor", 
      "Operador de vehículos pesados",
    ],
    respuestasCorrectas: ["Camionero"],
    fonetica: ["ˈlɔri ˈdraɪvər"],
  },
  {
    id: 4,
    texto: '',
    palabra: "truck driver",
    opciones: [
      "Camionero", 
      "Transportista", 
      "Chofer", 
      "Conductor de camiones", 
      "Repartidor", 
      "Operador de vehículos pesados",
    ],
    respuestasCorrectas: ["Camionero"],
    fonetica: ["trʌk ˈdraɪvər"],
  },
  {
    id: 5,
    texto: '',
    palabra: "Taxi driver",
    opciones: [
      "Conductor de taxi", 
      "Taxista", 
      "Chofer de taxi", 
      "Operador de taxi", 
      "Conductor de transporte", 
      "Chofer",
    ],
    respuestasCorrectas: ["Conductor de taxi"],
    fonetica: ["ˈtæksi ˈdraɪvər"],
  },
  {
    id: 6,
    texto: '',
    palabra: "Bus driver",
    opciones: [
      "Conductor de autobús", 
      "Chofer de bus", 
      "Operador de transporte", 
      "Conductor de bus urbano", 
      "Chofer de transporte público", 
      "Operador de bus",
    ],
    respuestasCorrectas: ["Conductor de autobús"],
    fonetica: ["bʌs ˈdraɪvər"],
  },
  {
    id: 7,
    texto: '',
    palabra: "Sailor",
    opciones: [
      "Marinero", 
      "Navegante", 
      "Capitán", 
      "Tripulante", 
      "Barquero", 
      "Timonel",
    ],
    respuestasCorrectas: ["Marinero"],
    fonetica: ["ˈseɪlər"],
  },
  {
    id: 8,
    texto: '',
    palabra: "Postman",
    opciones: [
      "Cartero", 
      "Mensajero", 
      "Repartidor de correo", 
      "Distribuidor de cartas", 
      "Funcionario postal", 
      "Operador de correo",
    ],
    respuestasCorrectas: ["Cartero"],
    fonetica: ["ˈpoʊstmən"],
  },
  {
    id: 9,
    texto: '',
    palabra: "mailman",
    opciones: [
      "Cartero", 
      "Mensajero", 
      "Repartidor de correo", 
      "Distribuidor de cartas", 
      "Funcionario postal", 
      "Operador de correo",
    ],
    respuestasCorrectas: ["Cartero"],
    fonetica: ["ˈmeɪlˌmæn"],
  },
];